<!--hướng dẫn
import CusttomSelect from '@/views/business/pages/components/CusttomSelect.vue'

 <custtom-select
           :setup = "{display:'name',data:'id'}"   mặt định là name và id    cài đặt hiển thị và nhận
            :vmodel="valueData.districtId"   liên kết để nhận biến
            :label="'Quận/Huyện'"            tên ô select
            :dataSelect="districts"          data truyền vào để có dữ liệu chọn
            @valueSelect="seclectDistricts"  sự kiện ô chọn
          />
-->
<template>
  <b-form-group
    label-for="basicInput"
  >
    <label
      for="label"
    >{{ label }}<span
      v-if="validee"
      class="required"
    >(*)</span></label>
    <validation-provider
      v-if="validee"
      #default="{ errors }"
      rules="required"
      :custom-messages="customVali"
    >
      <v-select
        v-model="vmodel"
        :reduce="data => data[setup.data]"
        :label="setup.display"
        :placeholder="`Lựa chọn ${label} `"
        :options="dataSelect || []"
        :class="{'is-invalid': !!errors.length}"
        :state="errors.length > 0 ? false:null"
        @input="data01()"
      >
        <template v-slot:no-options="{ searching }">
          <template v-if="searching">
            Không có kết quả.
          </template>
          <em
            v-else
          >Không có dữ liệu</em>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
    <v-select
      v-else
      v-model="vmodel"
      :reduce="data => data[setup.data]"
      :label="setup.display"
      :placeholder="`Lựa chọn ${label} `"
      :options="dataSelect || []"
      @input="data01()"
    >
      <template v-slot:no-options="{ searching }">
        <template v-if="searching">
          Không có kết quả.
        </template>
        <em
          v-else
        >Không có dữ liệu</em>
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    setup: {
      type: Object,
      default: _ => {},
    },
    // eslint-disable-next-line vue/require-prop-types
    label: {
      default: 'nhập props :label',
    },
    // eslint-disable-next-line vue/require-prop-types
    validee: {
      default: 'true',
    },
    // eslint-disable-next-line vue/require-prop-types
    dataSelect: {
      default: _ => [],
    },
    // eslint-disable-next-line vue/require-prop-types
    vmodel: {
      default: '',
    },

  },
  data() {
    return {
      customVali: {
        required: `${this.label} là bắt buộc`,
      },
      selectClassBusiness: [
        {
          id: 'StateEnterprises',
          name: 'Doanh nghiệp nhà nước',
        },
        {
          id: 'NonstateEnterPrises',
          name: 'Doanh nghiệp nước ngoài',
        },
        {
          id: 'ForeignInvestedEnterprises',
          name: 'Doanh nghiệp có vốn đầu tư nước ngoài',
        },
        {
          id: 'Organization',
          name: 'Tổ chức',
        },
      ],
      required,
    }
  },
  methods: {
    data01() {
      this.$emit('valueSelect', this.vmodel)
    },
  },

}
</script>

<style>

</style>
