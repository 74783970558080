<template>
  <div>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :contentBtnAdd="'Thêm người lao động'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        class="custtom-header"
        @search="search($event)"
        @clickAdd="$bvModal.show('addBusiness')"
      />
      <!--Phần Bảng -->
      <template>
        <vue-good-table
          :columns="columns"
          :rows="dataTable"
          :sort-options="{
            enabled: false,
          }"
          :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Chức năng -->
            <span v-if="props.column.field == 'cn'">
              <span>
                <feather-icon
                  icon="EditIcon"
                  size="18"
                />
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                  style="margin-left: 14px"
                  @click.stop="ShowFile(props.row.id)"
                />
              </span>

            </span>
          </template>
        </vue-good-table>
        <my-pagination
          :totalItems="dataTable.length"
          :currentPage="urlQuery.pageNumber"
          class="css-bottom"
          @pageClick="handlePageClick"
        />
      </template>
    </div>
    <Add
      :id="'addBusiness'"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import Add from './components/Add.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    Add,

  },
  data() {
    return {
      modalDetailUser: 'showFile',

      // ==dữ liệu bảng
      rows: [],
      columns: [
        {
          label: 'MÃ SỐ',
          field: 'number',
          tdClass: 'style-code',
        },
        {
          label: 'TÊN TAI NẠN LAO ĐỘNG',
          field: 'name',

        },
        {
          label: 'MÔ TẢ',
          field: 'description',
        },
        {
          label: 'CHỨC NĂNG',
          width: '200px',
          tdClass: 'actiontd',
          thClass: 'actionth',

          field: 'cn',
        },
      ],
      // ==phân trang
      totalPages: 0,
      currentPage: 10,
      // ==Dữ liệu fetch list
      urlQuery: {
        isOversea: null, // Lao động trong nước hay ngoài nước
        status: '', // Trạng thái cấp phép
        contractType: '', // lọc theo loại hợp đồng
        academicLevel: '', // Lọc theo trình độ học vấn
        key: '',
        pageSize: this.currentPage,
        pageNumber: 1,
      },
      dataTable: [
        {
          number: '00123',
          name: 'Tai nạn lao động làm chết người',
          description: 'Mô tả chi tiết',
        },
        {
          number: '00123',
          name: 'Máy vận thăng',
          description: 'Mô tả chi tiết',
        },
        {
          number: '00123',
          name: 'Máy vận thăng',
          description: 'Mô tả chi tiết',
        },
        {
          number: '00123',
          name: 'Nồi đung nước nóng',
          description: 'Mô tả chi tiết',
        },
        {
          number: '00123',
          name: 'Nồi đung nước nóng',
          description: 'Mô tả chi tiết',
        },
        {
          number: '00123',
          name: 'Nồi gia nhiệt dầu',
          description: 'Mô tả chi tiết',
        },
        {
          number: '00123',
          name: 'Nồi gia nhiệt dầu',
          description: 'Mô tả chi tiết',
        },
        {
          number: '00123',
          name: 'Máy vận thăng',
          description: 'Mô tả chi tiết',
        },
      ],
    }
  },
  // ======================WATCH=============================
  // ==========================COMPUTED=======================
  computed: {
    ...mapState('worker', ['totalRecord', 'listWorker']),
  },
  // ===========================CREATED====================
  async created() {
    const res = await this.doFetchListWorker(this.urlQuery)
    if (res.oke) {
      this.totalPages = this.totalRecord
      this.rows = this.listWorker
    }
  },
  // =============================METHOD======================
  methods: {
    ...mapActions('worker', ['doFetchListWorker', 'doDeleteWorker', 'dowloadFile', 'downloadExcelAll', 'getApiExcel']),
    // ================loc&Tìm kiếm=============
    // màu status
    // eslint-disable-next-line consistent-return
    colorStatus(e) {
      // eslint-disable-next-line default-case
      switch (e) {
        case 'Thu hồi giấy phép':
          return 'danger'
        case 'Duyệt giấy phép':
          return 'primary'
        case 'Đang chờ cấp lại':
          return 'secondary'
        // case 'Chưa cấp giấy':
        //   return 'light'
      }
    },
    // màu kiểu
    // eslint-disable-next-line consistent-return
    colorType(e) {
      // eslint-disable-next-line default-case
      switch (e) {
        case 'Giấy cấp phép':
          return 'type-blue'
        case 'Giấy xác nhận':
          return 'type-green'
      }
    },
    ShowFile(id) {
      this.idEdit = id
      this.$bvModal.show(this.modalDetailUser)
    },
    showTag() {
      this.$bvModal.show('showTag')
      // this.$router.push({ name: 'type-of-business-edit', params: { id: data } })
    },
    showTagRecall() {
      this.$bvModal.show('showTagRecall')
      // this.$router.push({ name: 'type-of-business-edit', params: { id: data } })
    },

    // lấy danh sách khi lọc
    async fetchList() {
      const res = await this.doFetchListWorker(this.urlQuery)
      if (res.oke) {
        this.totalPages = this.totalRecord
        this.rows = this.listWorker
      }
    },
    // lấy danh sách khi tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        await this.fetchList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        await this.fetchList()
      }
    },

  },
}
</script>

<style lang="scss">
#filter-custom{
  .header{
    font-size: 18px;
    color: #181F28;
  }
}
.actiontd{
  text-align: center;
}
.actionth{
  text-align: center;
}
.bg-color{
  color : #fff;
  text-align: center;
}
.inline{
  display: inline-block;
}
.custtom-header{
    padding-right: 20px;
}

.style-code {
  span {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
    padding: 1px 9px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #227ff4;
  }
}
.css-bottom{
padding-top: 70px;
}
</style>
