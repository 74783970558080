<template>
  <div>
    <b-modal
      :id="id"
      :hide-footer="true"
      centered
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      title="Thêm loại lọa tai nạn lao động"
      @show="resetModal"
    >
      <div class="form-input">
        <validation-observer ref="rule">
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputCode"
              >Mã số <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="customCodeVali"
              >
                <b-form-input
                  id="basicInputCode"
                  v-model=" codeValue "
                  :state="errors.length > 0 ? false:null"
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputName"
              >Tên loại tai nạn <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="customNameVali"
              >
                <b-form-input
                  id="basicInputName"
                  v-model=" nameValue "
                  :state="errors.length > 0 ? false:null"
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <custtom-select
            :setup="{display:'name',data:'id'}"
            :label="'Lựa chọn loại tai nạn'"
          />
          <div>
            <label for="textarea-default">Mô tả</label>
            <b-form-textarea
              id="textarea-default"
              v-model="descriptionValue"
              placeholder=""
              rows="3"
            />
          </div>
        </validation-observer>
      </div>
      <div class="action">
        <b-button
          v-if="!isNotification"
          variant="outline-danger mr-1 "
          @click="cancel"
        >
          Hủy bỏ
        </b-button>
        <b-button
          variant="primary"
          class="btn-left"
          @click="accept"
        >
          Lưu lại
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCardText, BButton, BFormGroup, BForm, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import CusttomSelect from '@/views/management-business/business/pages/components/CusttomSelect.vue'

export default {
  components: {
    BCardText,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    BFormInput,
    BFormTextarea,
    ToastificationContent,
    CusttomSelect,
  },
  props: {
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: String,
      default: 'confirm-modal',
    },
    // nếu là thông báo không có nút cancel
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checkName: false,
      checkCode: false,
      nameValue: '',
      codeValue: '',
      descriptionValue: '',
      customCodeVali: {
        required: 'Mã loại hình doanh nghiệp là bắt buộc',
      },
      customNameVali: {
        required: 'Tên loại hình doanh nghiệp là bắt buộc',
      },
      userTypeId: '',
      dataComboboxUserType: [],
    }
  },
  computed: {
  },
  methods: {
    accept() {
      this.$refs.rule.validate().then(async success => {
      })
    },
    resetModal() {
      this.nameValue = ''
      this.codeValue = ''
      this.descriptionValue = ''
    },
    cancel() {
      this.nameValue = ''
      this.codeValue = ''
      this.descriptionValue = ''
      this.$bvModal.hide(this.id)
      this.$emit('cancel')
    },
  },
}
</script>
<style scoped>
.action{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px 20px 20px;
  margin-top: 20px;
  border-top: 1px solid #CBCDD2 ;
}
</style>
